import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Event from 'components/Event';
import SEO from 'components/seo';
import get from 'lodash.get';

export default props => {
    let {
        data: {
            allContentfulEvent: { nodes: events },
            contentfulEvent,
        },
    } = props;

    if (!contentfulEvent) return 'No event details';

    let storedRegion = '';
    if (typeof window !== 'undefined') {
        storedRegion = localStorage.getItem('preferred-region');
    }

    let now = new Date();
    let futureEvents = events.filter(event => {
        let date = new Date(event.date);
        // Event already passed
        if (date - now < 0) return false;
        if (
            typeof event.region === 'undefined' ||
            event.region === null ||
            event.region.length === 0 ||
            event.region.includes(storedRegion)
        ) {
            return true;
        } else {
            return false;
        }
    });

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulEvent,
                    'seoListing.description.description'
                )}
                title={get(contentfulEvent, 'seoListing.seoTitle')}
                slug={contentfulEvent.url}
            />
            <Event event={contentfulEvent} otherEvents={futureEvents} />
        </Layout>
    );
};

export const query = graphql`
    query($contentful_id: String!, $locale: String) {
        contentfulEvent(
            contentful_id: { eq: $contentful_id }
            node_locale: { eq: $locale }
        ) {
            ...ContentfulEventFields
        }
        allContentfulEvent(
            filter: {
                contentful_id: { ne: $contentful_id }
                node_locale: { eq: $locale }
            }
        ) {
            nodes {
                name
                url
                image {
                    localFile {
                        publicURL
                    }
                }
                date
                endTime
                shortDescription {
                    shortDescription
                }
                region
            }
        }
    }
`;
