import React, { useState } from 'react';
import get from 'lodash.get';
import { parseISO, format } from 'date-fns';
import urls from 'helpers/url';
import _ from 'lodash';
import { injectIntl } from 'gatsby-plugin-react-intl';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import {
    Spacing,
    Container,
    SharedStyles,
    EventDetail,
    EventCalendar,
} from '../../kenra-storybook/index';
import { getEventUrl } from 'helpers/eventUrl';
const { StSectionInfo, StLink, StLinkMore } = SharedStyles;
const {
    StEventDetail,
    StEventDetailLeftCol,
    StEventDetailRightCol,
    StEventDetailMainImg,
    StEventDetailAboutLeftCol,
    StEventDetailAboutRightCol,
    StEventDetailAboutContainer,
    StEventDetailWhenContainer,
    StEventDetailIconTitle,
    StEventDetailLine,
    StDirectionButtonLink,
    StEventDetailFAQLink,
    StEventDetailInstructorContainer,
    StEventDetailInstructorImg,
} = EventDetail;

function getDirections(coordinates) {
    if (!coordinates || !coordinates.lat || !coordinates.lon) {
        return null;
    }
    window.open(
        `https://maps.google.com/?q=${coordinates.lat}, ${coordinates.lon}`,
        '_blank'
    );
}

function onSelectEvent(link) {
    navigate(link);
}

const Event = ({ intl, event, otherEvents }) => {
    const [showAddToCalendarButtons, setShowAddToCalendarButtons] = useState(
        false
    );
    const {
        name,
        aboutThisEvent,
        aboutThisEventRtf,
        preparation,
        aboutTheInstructor,
        bookingButtonLink,
        bookingButtonText,
        whereCoOrdinates,
        where,
        date,
        endTime,
        image,
        instructorImage,
        icsFile,
    } = event;

    const imageUrl = get(image, 'localFile.publicURL');
    const instructorImageUrl = get(instructorImage, 'localFile.publicURL');
    let tz = null;
    if (typeof window !== `undefined` && navigator && navigator.language) {
        tz = /.*\s(.+)/.exec(
            new Date().toLocaleDateString(navigator.language, {
                timeZoneName: 'short',
            })
        );
    }
    const tzName = tz && tz.length > 1 && tz[1] ? tz[1] : null;

    let whereLines = null;
    if (where && where.where) {
        whereLines = where.where.split('\n');
    }

    let aboutTheInstructorLines = null;
    if (aboutTheInstructor && aboutTheInstructor.aboutTheInstructor) {
        aboutTheInstructorLines = aboutTheInstructor.aboutTheInstructor.split(
            '\n'
        );
    }

    // sort the otherEvents into date order
    otherEvents.forEach(event => {
        event.actualDate = new Date(event.date);
    });
    otherEvents = _.orderBy(otherEvents, ['actualDate']);

    let calendarEvents = otherEvents.map(event => {
        return {
            img: get(event, 'image.localFile.publicURL'),
            title: event.name,
            dayWeek: format(parseISO(event.date), 'E'),
            date: format(parseISO(event.date), 'MMMM d, Y'),
            actualDate: new Date(event.date),
            time: format(parseISO(event.date), 'h:mma'),
            endDate: new Date(event.endTime),
            link: getEventUrl(event),
            shortDescription: get(event, 'shortDescription.shortDescription'),
        };
    });

    let aboutThisEventText = '';
    if (
        typeof aboutThisEventRtf !== 'undefined' &&
        aboutThisEventRtf !== null
    ) {
        aboutThisEventText = documentToReactComponents(
            JSON.parse(aboutThisEventRtf.raw || '{}')
        );
    }

    return (
        <>
            <Spacing removeSpaceBottom>
                <StSectionInfo>
                    <Container style={{ maxWidth: '1070px' }}>
                        <StEventDetail>
                            <StEventDetailLeftCol>
                                {imageUrl && (
                                    <StEventDetailMainImg
                                        style={{
                                            backgroundImage: `url(${imageUrl}`,
                                        }}
                                    />
                                )}
                                <h1>{name}</h1>
                                {aboutThisEventText && (
                                    <StEventDetailAboutContainer>
                                        <StEventDetailAboutLeftCol>
                                            <h2>
                                                {intl.formatMessage({
                                                    id: 'event-about',
                                                })}
                                            </h2>
                                        </StEventDetailAboutLeftCol>
                                        <StEventDetailAboutRightCol>
                                            {aboutThisEventText}
                                        </StEventDetailAboutRightCol>
                                    </StEventDetailAboutContainer>
                                )}
                                {preparation && preparation.preparation && (
                                    <StEventDetailAboutContainer>
                                        <StEventDetailAboutLeftCol>
                                            <h2>
                                                {intl.formatMessage({
                                                    id: 'event-preparation',
                                                })}
                                            </h2>
                                        </StEventDetailAboutLeftCol>
                                        <StEventDetailAboutRightCol>
                                            <p>{preparation.preparation}</p>
                                        </StEventDetailAboutRightCol>
                                    </StEventDetailAboutContainer>
                                )}
                            </StEventDetailLeftCol>
                            <StEventDetailRightCol>
                                <StEventDetailWhenContainer>
                                    <StEventDetailIconTitle>
                                        <img src="https://static.thenounproject.com/png/376090-200.png" />
                                        <h3>
                                            {' '}
                                            {intl.formatMessage({
                                                id: 'event-when-occurs',
                                            })}
                                        </h3>
                                    </StEventDetailIconTitle>
                                    {date && (
                                        <h2>
                                            {format(
                                                parseISO(date),
                                                'MMMM d, Y'
                                            )}
                                        </h2>
                                    )}
                                    {date && endTime && (
                                        <h4>
                                            {format(parseISO(date), 'h:mm')}
                                            &ndash;
                                            {format(parseISO(endTime), 'h:mma')}
                                            {tzName ? ` ${tzName}` : ``}
                                        </h4>
                                    )}
                                    {date && !endTime && (
                                        <h4>
                                            {format(parseISO(date), 'h:mma')}
                                            {tzName ? ` ${tzName}` : ``}
                                        </h4>
                                    )}
                                    <StEventDetailLine />
                                    <StEventDetailIconTitle>
                                        <img src="https://static.thenounproject.com/png/376090-200.png" />
                                        <h3>
                                            {' '}
                                            {intl.formatMessage({
                                                id: 'event-where-occurs',
                                            })}
                                        </h3>
                                    </StEventDetailIconTitle>
                                    {whereLines && (
                                        <>
                                            {whereLines.length > 0 &&
                                                whereLines[0] && (
                                                    <h2>{whereLines[0]}</h2>
                                                )}
                                            {whereLines.length > 1 &&
                                                whereLines[1] && (
                                                    <h4>{whereLines[1]}</h4>
                                                )}
                                            {whereLines.length > 2 &&
                                                whereLines[2] && (
                                                    <h4
                                                        style={{
                                                            fontWeight: 500,
                                                            paddingTop: '10px',
                                                            paddingBottom:
                                                                '10px',
                                                        }}
                                                    >
                                                        {whereLines[2]}
                                                    </h4>
                                                )}
                                        </>
                                    )}
                                    {whereCoOrdinates && (
                                        <StDirectionButtonLink>
                                            <StLink>
                                                <p
                                                    onClick={() => {
                                                        getDirections(
                                                            whereCoOrdinates
                                                        );
                                                    }}
                                                >
                                                    {intl.formatMessage({
                                                        id: 'event-direction',
                                                    })}
                                                </p>
                                            </StLink>
                                        </StDirectionButtonLink>
                                    )}
                                    <StLinkMore>
                                        <a
                                            style={{
                                                minWidth: '80%',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                            }}
                                            href={bookingButtonLink}
                                        >
                                            {bookingButtonText ||
                                                intl.formatMessage({
                                                    id: 'event-book-now',
                                                })}
                                        </a>
                                    </StLinkMore>
                                    <StLinkMore
                                        inversionColorNotHover
                                        style={{ marginTop: '16px' }}
                                    >
                                        <a
                                            style={{
                                                minWidth: '80%',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                            }}
                                            onClick={() =>
                                                setShowAddToCalendarButtons(
                                                    !showAddToCalendarButtons
                                                )
                                            }
                                        >
                                            {intl.formatMessage({
                                                id: 'event-add-to-calendar',
                                            })}
                                        </a>
                                    </StLinkMore>
                                    {showAddToCalendarButtons && (
                                        <div
                                            style={{
                                                position: 'relative',
                                                top: 0,
                                                left: 0,
                                            }}
                                        >
                                            <StLinkMore
                                                style={{ marginTop: '0px' }}
                                            >
                                                <a
                                                    style={{
                                                        minWidth: '80%',
                                                        marginLeft: '20px',
                                                        marginRight: '20px',
                                                    }}
                                                    target="_blank"
                                                    href={`https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
                                                        name
                                                    )}&dates=${format(
                                                        parseISO(date),
                                                        "yyyy-MM-dd'T'HH:mm:ssXX"
                                                    )}/${format(
                                                        parseISO(
                                                            endTime || date
                                                        ),
                                                        "yyyy-MM-dd'T'HH:mm:ssXX"
                                                    )}&location=${encodeURIComponent(
                                                        where.where
                                                    )}`}
                                                >
                                                    Google
                                                </a>
                                            </StLinkMore>
                                            {icsFile && (
                                                <StLinkMore
                                                    style={{ marginTop: '0px' }}
                                                >
                                                    <a
                                                        style={{
                                                            minWidth: '80%',
                                                            marginLeft: '20px',
                                                            marginRight: '20px',
                                                        }}
                                                        href={
                                                            icsFile?.localFile
                                                                ?.publicURL
                                                        }
                                                    >
                                                        iCalendar
                                                    </a>
                                                </StLinkMore>
                                            )}
                                            <StLinkMore
                                                style={{ marginTop: '0px' }}
                                            >
                                                <a
                                                    style={{
                                                        minWidth: '80%',
                                                        marginLeft: '20px',
                                                        marginRight: '20px',
                                                    }}
                                                    target="_blank"
                                                    href={`https://outlook.office.com/owa/?path=/calendar/action/compose&rrv=addevent&startdt=${format(
                                                        parseISO(date),
                                                        "yyyy-MM-dd'T'HH:mm:ssXX"
                                                    )}&enddt=${format(
                                                        parseISO(
                                                            endTime || date
                                                        ),
                                                        "yyyy-MM-dd'T'HH:mm:ssXX"
                                                    )}&location=${encodeURIComponent(
                                                        where.where
                                                    )}&subject=${encodeURIComponent(
                                                        name
                                                    )}`}
                                                >
                                                    Outlook
                                                </a>
                                            </StLinkMore>
                                        </div>
                                    )}
                                    <StEventDetailFAQLink>
                                        <p>
                                            {intl.formatMessage({
                                                id: 'event-questions-1',
                                            })}{' '}
                                            <a href={urls.faq}>
                                                {intl.formatMessage({
                                                    id: 'event-questions-2',
                                                })}
                                            </a>
                                        </p>
                                    </StEventDetailFAQLink>
                                </StEventDetailWhenContainer>
                                {aboutTheInstructorLines &&
                                    aboutTheInstructorLines.length > 0 && (
                                        <StEventDetailInstructorContainer>
                                            <h2>
                                                {intl.formatMessage({
                                                    id:
                                                        'event-about-instructor',
                                                })}
                                            </h2>
                                            {instructorImageUrl && (
                                                <StEventDetailInstructorImg
                                                    style={{
                                                        backgroundImage: `url(${instructorImageUrl})`,
                                                    }}
                                                />
                                            )}
                                            {aboutTheInstructorLines &&
                                                aboutTheInstructorLines.map(
                                                    (line, index) => {
                                                        return (
                                                            <p
                                                                key={`line-${index}`}
                                                            >
                                                                {line}
                                                            </p>
                                                        );
                                                    }
                                                )}
                                        </StEventDetailInstructorContainer>
                                    )}
                            </StEventDetailRightCol>
                        </StEventDetail>
                    </Container>
                </StSectionInfo>
            </Spacing>

            {otherEvents && otherEvents.length > 0 && (
                <Spacing removeSpaceTop>
                    <Container>
                        <EventCalendar
                            title={intl.formatMessage({
                                id: 'event-upcoming-events',
                            })}
                            items={calendarEvents}
                            onSelect={onSelectEvent}
                        />
                    </Container>
                </Spacing>
            )}
        </>
    );
};

export default injectIntl(Event);
